<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Certificates Settings component
 */
export default {
  page: {
    title: "Minha Conta",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Minha Conta",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Minha Conta",
          active: true,
        },
      ],
    };
  },
  methods: {
    handleNewCad() {
      this.$router.push("/category/create");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Informação básica</h4>
            <form
              class="form-horizontal form-groups-bordered validate"
              target="_top"
              enctype="multipart/form-data"
              method="post"
              accept-charset="utf-8"
            >
              <div class="form-group mb-3">
                <label>Nome</label>
                <input
                  type="text"
                  class="form-control"
                  name="first_name"
                  value="Admin"
                  required=""
                />
              </div>

              <div class="form-group mb-3">
                <label>Sobrenome</label>
                <input
                  type="text"
                  class="form-control"
                  name="last_name"
                  value="Speed"
                  required=""
                />
              </div>

              <div class="form-group mb-3">
                <label>E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  value=""
                  required=""
                />
              </div>

              <div class="form-group mb-3">
                <label>
                  Foto
                  <small
                    >(O tamanho da imagem deve ser qualquer imagem
                    quadrada)</small
                  >
                </label>
                <div class="row">
                    <div class="col">
                      <div class="mb-3 w-100">
                        <label class="form-label" for="validationCustom02">Imagem de Capa da Categoria (O tamanho da imagem deve ser: 400 X 255)</label>
                        <div class="d-flex w-100">
                          <img
                            class="rounded-circle img-thumbnail"
                            src="https://sistema.speednow.com.br/uploads/user_image/7c44fb064b8f8d4f856df4c91bc2a6c1.jpg"
                            alt=""
                            style="height: 50px; width: 50px"
                          />
                          <div class="w-100" style="margin-left: 10px">
                            <input
                              id="validationCustom02"
                              type="file"
                              class="form-control w-100"
                              placeholder="Ex: 1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="row">
                <div class="col d-flex justify-content-center w-100">
                  <button type="submit" class="btn btn-primary">
                    Atualizar Perfil
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card body-->
        </div>
        <!-- end card -->
      </div>
      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <form
              class="form-horizontal form-groups-bordered validate"
              target="_top"
              method="post"
              accept-charset="utf-8"
            >
              <div class="form-group mb-3">
                <label>Senha atual</label>
                <input
                  type="password"
                  class="form-control"
                  name="current_password"
                  value=""
                  required=""
                />
              </div>
              <div class="form-group mb-3">
                <label>Nova Senha</label>
                <input
                  type="password"
                  class="form-control"
                  name="new_password"
                  value=""
                  required=""
                />
              </div>
              <div class="form-group mb-3">
                <label>Confirme a nova senha</label>
                <input
                  type="password"
                  class="form-control"
                  name="confirm_password"
                  value=""
                  required=""
                />
              </div>
              <div class="row justify-content-center">
                <div class="d-flex justify-content-center w-100">
                  <button type="submit" class="btn btn-info">
                    Atualizar senha
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
